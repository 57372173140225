import React, { Component } from 'react';

class Hire extends Component {
  render() {
    return (
        <section id="hire" className="bg-color-light-blue">

            <div className="container">
                <div className="text-center">
                    <img id="hire-image" className="d-inline-block mx-auto rounded-circle mb-3"
                         src="/hire/profile.png" alt="Hire"/>
                </div>
                <h3 className="text-center text-white font-weight-bold">{this.props.data.title}</h3>
                <div id="hire-description" className="mx-auto text-center text-white" dangerouslySetInnerHTML={{__html: this.props.data.description}}></div>
            </div>

        </section>
    );
  }
}

export default Hire;