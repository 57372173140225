import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Interests extends Component {
  render() {
    return (
        <section id="interests">

            <div className="container">
                <h1 className="section-title text-center mb-5">{this.props.title}</h1>
                <ScrollAnimation animateIn="fadeIn" animateOnce>
                    <p className="text-center">{this.props.data.description}</p>
                </ScrollAnimation>
                <div className="row pt-4">
                    {this.props.data.items.map((interest, index) => {
                        return <div key={index} className="col text-center">
                            <ScrollAnimation animateIn="rotateIn" animateOnce>
                                <h1><FontAwesomeIcon className={"mr-1 color-light-blue"} icon={['fa', interest.icon]} style={{'opacity': '0.7'}} /></h1>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <h5>{interest.title}</h5>
                            </ScrollAnimation>
                        </div>;
                    })}
                </div>


            </div>

        </section>
    );
  }
}

export default Interests;
