import React, { Component } from 'react';
import SkillsBox from './Skills/SkillsBox';
import { FaFileCode, FaCode, FaTools, FaLayerGroup, FaGamepad  } from 'react-icons/fa';
import { MdRecordVoiceOver  } from 'react-icons/md';

class Skills extends Component {
  render() {
    return (
        <section id="skills">
            <div className="container">
                <h1 className="section-title text-center mb-5">{this.props.title}</h1>
                <div className="skills-blocks mx-auto">
                    <div className="row">
                        <SkillsBox data={this.props.data.frameworks} icon={<FaFileCode className="mr-3" />} animation="bounceInRight" color="color-light-blue" />
                        <SkillsBox data={this.props.data.programming_languages} icon={<FaCode className="mr-3" />} animation="bounceInRight" color="color-light-red" />
                        <SkillsBox data={this.props.data.tools} icon={<FaTools className="mr-3" />} animation="bounceInRight" color="color-light-green" />
                        <SkillsBox data={this.props.data.methodology_patterns} icon={<FaLayerGroup className="mr-3" />} animation="bounceInLeft" color="color-light-yellow" />
                        <SkillsBox data={this.props.data.human_languages} icon={<MdRecordVoiceOver className="mr-3" />} animation="bounceInLeft" color="color-light-purple" />
                        <SkillsBox data={this.props.data.game_development} icon={<FaGamepad className="mr-3" />} animation="bounceInLeft" color="color-light-grey" />
                    </div>
                </div>

            </div>
        </section>
    );
  }
}

export default Skills;
