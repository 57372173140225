import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ProjectModal from './Projects/ProjectModal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tab, Nav } from 'react-bootstrap';

function Projects(props) {

    const [modalShow, setModalShow] = useState(false);
    const [category, setCategory] = useState('all');
    const [selectedProject, setSelectedProject] = useState({});

    const settings = {
        className: "projects-gallery",
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        rows: 2,
        slidesPerRow: 3,
        slidesToScroll: 1,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesPerRow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesPerRow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section id="projects" className="bg-color-light-white">

            <ProjectModal
                show={modalShow}
                selectedProject={selectedProject}
                featuresDescription={props.data.features_description}
                onHide={() => setModalShow(false)}
            />

            <div className="container">
                <h1 className="section-title text-center mb-5">{props.title}</h1>

                <div id="projects-tabs" className="mb-3">
                    <Tab.Container id="tab-cont" defaultActiveKey="all" onSelect={(activeKey) => setCategory(activeKey)}>
                            <Nav className="nav-tabs justify-content-center">
                                {props.data.categories.map((category, index) => {
                                    return <Nav.Item key={index}>
                                        <Nav.Link eventKey={category.code}>{category.name}</Nav.Link>
                                    </Nav.Item>;
                                })}
                            </Nav>
                    </Tab.Container>
                </div>

                <Slider id="projects-gallery" {...settings}>
                    {props.data.items.map((project, index) => {
                        if ((category === "all") || (project.categories.indexOf(category) > -1)) {
                            return (<div key={index} className="projects-box">
                                <div className="projects-box-wrapper" onClick={() => {setModalShow(true); setSelectedProject(project);}}>
                                    <ScrollAnimation animateIn="zoomIn" animateOnce>
                                        <img src={"/projects/" + project.thumbnail} alt={project.title}/>
                                    </ScrollAnimation>
                                    <div className="projects-box-overlay">
                                        <div className="projects-box-title"><h5>{project.title}</h5></div>
                                    </div>
                                </div>
                            </div>);
                        } else {
                            return null;
                        }
                    })}
                </Slider>

            </div>

        </section>
    );

}

export default Projects;
