import React, { Component } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaPlane, FaBaby } from 'react-icons/fa';
import { MdWork, MdSchool } from 'react-icons/md';

class Career extends Component {

    components = {
        faPlane: FaPlane,
        faBaby: FaBaby,
        mdWork: MdWork,
        mdSchool: MdSchool
    };

  render() {
    return (
        <div id="canvas-after-about"  className="bg-color-light-white">

            <section id="career">
                <div className="container">
                    <h1 className="section-title text-center mb-5">{this.props.title}</h1>
                    <VerticalTimeline>
                        {this.props.data.items.map((experience, index) => {
                            const IconTagName = this.components[experience.icon];
                            return <VerticalTimelineElement
                                    key={index}
                                    className="vertical-timeline-element--work"
                                    date={experience.date}
                                    iconStyle={{ background: experience.icon_bg, color: '#fff' }}
                                    icon={<IconTagName />}
                            >
                                {((experience.title != null)) && <h4 className="vertical-timeline-element-title">{experience.title}</h4>}
                                {((experience.subtitle != null)) && <h6 className="vertical-timeline-element-subtitle pt-2">{experience.subtitle}</h6>}
                                <div className={((experience.title != null) || (experience.subtitle != null)) ? "pt-3" : ""} dangerouslySetInnerHTML={{__html: experience.description}}>
                                </div>
                                {((experience.company_logo != null)) && <div className="text-right pt-3">
                                    <a href={experience.company_website}>
                                    <img className="career-company-logo"
                                         src={"/career/" + experience.company_logo} alt={experience.company_logo}/>
                                    </a>
                                </div>}
                            </VerticalTimelineElement>;
                        })}
                    </VerticalTimeline>

                </div>
            </section>

        </div>
    );
  }
}

export default Career;
