import React from 'react';
import { Modal } from 'react-bootstrap';
import Slider from "react-slick";

function ProjectModal(props) {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    return (
        <Modal
             {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="px-lg-4" closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.selectedProject.title}
                    <br/><h6>{props.selectedProject.date}</h6>
                    {((props.selectedProject.badges != null) && (props.selectedProject.badges.length > 0)) && <div>
                            {props.selectedProject.badges.map((badge, index) => {
                                return <span class="badge rounded bg-color-light-blue text-white mr-2">{badge}</span>;
                        })}
                        </div>
                    }

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-lg-4">
                {((props.selectedProject.images != null) && (props.selectedProject.images.length > 0)) && <Slider {...settings}>
                    {props.selectedProject.images.map((image, index) => {
                        return <img src={"/projects/" + image} alt={index}/>;
                    })}
                    </Slider>
                }
                <div id="project-description" className="pt-5" dangerouslySetInnerHTML={{__html: props.selectedProject.description}}>
                </div>
                {((props.selectedProject.features != null) && (props.selectedProject.features.length > 0)) &&
                    <div id="project-features" className="pt-4">
                        <div>{props.featuresDescription}</div>
                        <ul className="list-group list-group-flush">
                            {props.selectedProject.features.map((feature, index) => {
                                return <li className="list-group-item">{feature}</li>;
                            })}
                        </ul>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}

export default ProjectModal;
