import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
        <footer className="footer bg-color-dark-grey text-light text-center py-2">

          <small>© 2019 Geoffrey Vincent</small>
        </footer>
    );
  }
}

export default Footer;