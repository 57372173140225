import React, {Component} from 'react';
import ReactGA from 'react-ga';
import detectBrowserLanguage from 'detect-browser-language';

import './animate.min.css';
import './App.css';

import Header from './Components/Header';
import About from './Components/About';
import Career from './Components/Career';
import Skills from './Components/Skills';
import Projects from './Components/Projects';
import Interests from './Components/Interests';
import Hire from './Components/Hire';
import Footer from './Components/Footer';

import BackToTop from 'react-back-to-top-button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fab, far, fas);

const languagesOptions =
    [
        {
            "iso639_3": "fra",
            "name": "Français"
        },
        {
            "iso639_3": "eng",
            "name": "English"
        },
        {
            "iso639_3": "jpn",
            "name": "日本語"
        }
    ];

class App extends Component {

    constructor(props) {
        super(props);
        switch (detectBrowserLanguage()) {
            case 'fr':
            case 'fr-BE':
            case 'fr-CA':
            case 'fr-CH':
            case 'fr-FR':
            case 'fr-LU':
            case 'fr-MC':
                this.state = {
                    selectedLanguage: languagesOptions[0], // default selected value
                    resumeData: require(`./data/languages/data-fra.json`)
                };
                break;

            case 'ja':
            case 'ja-JP':
                this.state = {
                    selectedLanguage: languagesOptions[2], // default selected value
                    resumeData: require(`./data/languages/data-jpn.json`)
                };
                break;

            default:
                this.state = {
                    selectedLanguage: languagesOptions[1], // default selected value
                    resumeData: require(`./data/languages/data-eng.json`)
                };
                break;
        }


        // Events
        this.handleLanguageSelect = this.handleLanguageSelect.bind(this);

        ReactGA.initialize('UA-110570651-1');
        ReactGA.pageview(window.location.pathname);
    }

    handleLanguageSelect(eventKey) {
        const resumeData = require(`./data/languages/data-${languagesOptions[eventKey].iso639_3}.json`);

        this.setState({
            selectedLanguage: languagesOptions[eventKey],
            resumeData: resumeData
        })
    }

    render() {
        return (
            <div className="App">
                <Header handleLanguageSelect={this.handleLanguageSelect} languages={languagesOptions} selectedLanguage={this.state.selectedLanguage} data={this.state.resumeData.menu}/>
                <About data={this.state.resumeData.about}/>
                <Career title={this.state.resumeData.menu.career} data={this.state.resumeData.career}/>
                <Skills title={this.state.resumeData.menu.skills} data={this.state.resumeData.skills}/>
                <Projects title={this.state.resumeData.menu.projects} data={this.state.resumeData.projects}/>
                <Interests title={this.state.resumeData.menu.interests} data={this.state.resumeData.interests}/>
                <Hire data={this.state.resumeData.hire}/>
                <Footer/>
                <BackToTop
                showAt={100}
                speed={2000}
                easing="easeInOutQuint"
                style={{'fontSize': 'inherit', 'zIndex': '99'}}
                >
                    <div id="topcontrol">
                        <FontAwesomeIcon icon={['fas', 'angle-up']} />
                    </div>
                </BackToTop>
            </div>
        );
    }
}

export default App;
