import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';

class About extends Component {

  render() {

    const networks = this.props.data.social.map(function(network){
        return <li className="mx-2 mx-sm-3" key={network.name}><a rel="noopener noreferrer" target="_blank" href={network.url}><FontAwesomeIcon icon={['fab', network.icon]} /></a></li>
    })

    return (
        <div className="bg-color-light-blue">
          <div id="about" className="container">
              <ScrollAnimation animateIn="pulse" animateOnce>
                <div id="canvas-about" className="rounded">
                    <div className="profile p-3 p-md-4 p-lg-5">

                        <div className="row">
                            <div className="col-md-5">
                                <div className="profile-photo mb-3 mb-md-0 mr-5 mr-md-2 mr-lg-4 ml-md-0 rounded mx-auto">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
											{/* get a better photo
                                            <div className="tricky">
                                                <img className="rounded" src="/about/profile.jpg" alt="tricky"/>
                                            </div>
                                            <div className="flip-card-front">
                                                <img className="rounded" src="/about/profile.jpg" alt="profile"/>
                                            </div>
											<div className="flip-card-back">
											*/}
                                            <div className="profile">
                                                <img className="rounded" src="/about/animx.gif" alt="3D chibi profile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="profile-info">
                                    <div className="profile-hello"><span className="rounded bg-color-light-blue">{this.props.data.hello}</span></div>
                                    <h1 className="profile-title" dangerouslySetInnerHTML={{__html: this.props.data.title}}></h1>
                                    <h2 className="profile-occupation">{this.props.data.occupation}</h2></div>
                                <div className="profile-bio" dangerouslySetInnerHTML={{__html: this.props.data.bio}}>
                                </div>
                                <div id="contact" className="text-center text-md-left">
                                    <ul id="social">
                                        <li className="mx-2 mx-sm-3" key="mail"><a href={this.props.data.mail}><FontAwesomeIcon icon={['far', 'envelope']} /></a></li>
                                        {networks}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </ScrollAnimation>
          </div>
        </div>
    );
  }
}

export default About;
