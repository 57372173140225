import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SkillsBox extends Component {
  render() {
      const { data, icon, animation, color } = this.props;

    return (
        <div className="skills-block col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
            <ScrollAnimation animateIn={animation} animateOnce>
                <div className="skills-block-inner bg-white rounded">
                    <div className={"skills-header py-3 text-center text-white bg-" + color}>
                        <h5 className="skills-title mb-0">
                            {icon}
                            {data.title}
                        </h5>
                    </div>
                    <ul className="skills-list py-4 px-4 mb-0 list-unstyled text-center ">
                        {data.skills.map((skill, index) => {
                            if (typeof skill === 'string') {
                                return <li key={index} className="mb-2">{skill}</li>;
                            } else {
                                return <li key={index} className="text-left mb-3">{skill.name}<span className="text-secondary ml-2">({skill.level.description})</span>
                                    <div>
                                        {Array.apply(null, Array(skill.level.star)).map((obj, index2) => {
                                            return <FontAwesomeIcon key={index2} className={"mr-1 " + color} icon={['fas', 'star']} style={{'opacity': '0.7'}} />;
                                        })}
                                    </div>
                                </li>;
                            }
                        })}
                    </ul>
                </div>
            </ScrollAnimation>
        </div>
    );
  }
}

export default SkillsBox;
