import React, {Component} from 'react';
import classnames from "classnames";
import Scrollspy from 'react-scrollspy'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import {BrowserView} from 'react-device-detect';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            menuCollapsed: false
        };
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    // Hide or show the navbar
    handleScroll = () => {
        this.setState({
            visible: ((window.pageYOffset > 0) || (this.state.menuCollapsed))
        });
    };

    handleToggle = () => {
        this.setState({
            menuCollapsed: !this.state.menuCollapsed,
            visible: ((window.pageYOffset > 0) || (!this.state.menuCollapsed))
        });
    };

    render() {
        return (
            <header>

                <Navbar bg="color-dark-blue" variant="dark" fixed="top" expand="lg" collapseOnSelect={true} onToggle={this.handleToggle} className={classnames("justify-content-center", {
                    "navbar--hidden": !this.state.visible
                })}>

                    <Container className="my-auto">
                        <div id="logo" className="">
                            <Navbar.Brand href="/">
                                <img alt="GV" src="logo_gv.svg"/>
                            </Navbar.Brand>
                        </div>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Scrollspy className="navbar-nav mx-auto text-uppercase" items={ ['gamefolio', 'about', 'career', 'skills', 'projects', 'interests'] } offset={-300} currentClassName="active">
                                <BrowserView>
                                    <li className="nav-item">
                                        <Nav.Link id="gamefolio" href="/gamefolio" className="btn btn-primary mr-2" role="button">{this.props.data.gamefolio}</Nav.Link>
                                    </li>
                                </BrowserView>

                                <li className="nav-item">
                                    <Nav.Link href="#about">{this.props.data.about}</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="#career">{this.props.data.career}</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="#skills">{this.props.data.skills}</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="#projects">{this.props.data.projects}</Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link href="#interests">{this.props.data.interests}</Nav.Link>
                                </li>
                            </Scrollspy>
                            <Nav className={classnames("mb-sm-3 mb-lg-0 ", {"w-25": this.state.menuCollapsed})}>
                                <NavDropdown title={this.props.selectedLanguage.name} id="languages"
                                    onSelect={this.props.handleLanguageSelect}>
                                        {this.props.languages.map((opt, i) => {
                                            if (opt.iso639_3 !== this.props.selectedLanguage.iso639_3) {
                                                return <NavDropdown.Item key={i} eventKey={i}>
                                                    {opt.name}
                                                </NavDropdown.Item>
                                            }
                                            return null;
                                        })}
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>

                    </Container>

                </Navbar>

            </header>
        );
    }
}

export default Header;
